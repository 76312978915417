import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {Injectable} from "@angular/core";
import {SessionManageMent} from "./SessionManageMent";
import {filter} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {

  validURL:Array<any> =[];

  constructor(private _authService: SessionManageMent,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private  _toaaster : ToastrService,
  ) {
    this.validURL.push("/dashboard/maindashboard");
    this.validURL.push("/");
    this.validURL.push("/pages/auth/login-2");
    this.validURL.push("/employeeticket/employeeticket");
    for(let i=0; i < this._authService.getMenu[0].children.length; i++){
      for(let j=0; j<this._authService.getMenu[0].children[i].children.length; j++){
        this.validURL.push(this._authService.getMenu[0].children[i].children[j].url);
      }
    }
    if(this.validURL.indexOf("/product/licence_list")!=-1){
      this.validURL.push("/product/licence_create");
    }
    if(this.validURL.indexOf("/ticket/ticketlist")!=-1){
      this.validURL.push("/ticket/ticketaction");
    }
    if(this.validURL.indexOf("/ticket/ticketaction")!=-1){
      this.validURL.push("/ticket/comment");
    }
    if(this.validURL.indexOf("/support/supportaggrement")!=-1){
      this.validURL.push("/subscription/addsubscription");
    }
    if(this.validURL.indexOf("/master/emppayelementmasterlist")!=-1){
      this.validURL.push("/people/emppayelementcreate");
    }
    if(this.validURL.indexOf("/review/timesheetlist")!=-1){
      this.validURL.push("/review/timesheetcreate");
      this.validURL.push("/review/timesheetaction");
    }
    if(this.validURL.indexOf("/appraisal/cycle_setup")!=-1){
      this.validURL.push("/appraisal/create_cycle_setup");
    }
    if(this.validURL.indexOf("/appraisal/goal")!=-1){
      this.validURL.push("/appraisal/goal_setup");
    }
    if(this.validURL.indexOf("/recuitment/candidatelist")!=-1){
      this.validURL.push("/recuitment/candidatedata");
    }
    if(this.validURL.indexOf("/people/attendance_second")!=-1){
      this.validURL.push("/people/attendance_submit");
    }
    if(this.validURL.indexOf("/people/employeelist")!=-1){
      this.validURL.push("/people/employeeadd");
    }
    if(this.validURL.indexOf("/recuitment/submittedapplicationlist")!=-1){
      this.validURL.push("/candidate/applicationform");
    }
    if(this.validURL.indexOf("/recuitment/submittedapplicationlist")!=-1){
      this.validURL.push("/candidate/applicationform");
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any{
    if (this._authService.getEmail != "") {
      // console.log(this.validURL);
      this._router.events.pipe(
        filter((event => event instanceof NavigationEnd))
      ).subscribe((a:any) => {
        // console.log(a.urlAfterRedirects.split(';')[0]);
        if(this.validURL.indexOf(a.urlAfterRedirects.split(';')[0].split('?')[0])==-1){
          this._router.navigateByUrl('/dashboard/maindashboard');
        }
      });
      return true
    }else{
      this._router.navigateByUrl('/pages/auth/login-2');
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraysum'
})
export class ArraysumPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let x = 0;
    for(let i=0;i<value.length;i++){
      x+=value[i];
    }
    return x;
  }
}

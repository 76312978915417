import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {RouterModule, Routes} from '@angular/router';
import {pristineSharedModule} from '../@pristine/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {pristineProgressBarModule, pristineSidebarModule, pristineThemeOptionsModule} from '../@pristine/components';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {pristineModule} from '../@pristine/pristine.module';
import {pristineConfig} from './pristine-config';
import {LayoutModule} from './layout/layout.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDividerModule} from '@angular/material/divider';
import {MatTableModule} from '@angular/material/table';
import { RoleMasterComponent } from './main/Setup/role-master/role-master.component';

const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'recuitment',
    loadChildren: () => import('./main/recruitment/recruitment.module').then(m => m.RecruitmentModule)
  },
  {
    path: 'candidate',
    loadChildren: () => import('./main/candidate/candidate.module').then(m => m.CandidateModule)
  },
  {
    path: 'leave',
    loadChildren: () => import('./main/leave/leave.module').then(m => m.LeaveModule)
  },
  {
    path: 'master',
    loadChildren: () => import('./main/master/master.module').then(m => m.MasterModule)
  },
  {
    path: 'people',
    loadChildren: () => import('./main/people/people.module').then(m => m.PeopleModule)
  },
  {
    path: 'appraisal',
    loadChildren: () => import('./main/appraisal/appraisal.module').then(m => m.AppraisalModule)
  },
  {
    path: 'review',
    loadChildren: () => import('./main/review/review.module').then(m => m.ReviewModule)
  },
  {
    path: 'ticket',
    loadChildren: () => import('./main/ticket/Ticket.module').then(m => m.TicketModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./main/customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./main/product//product.module').then(m =>m.ProductModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./main/subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  {
    path:'notify',
    loadChildren:() => import('./main/notify/notify.module').then(m=>m.NotifyModule)
  },
  {
    path:'user',
    loadChildren:() => import('./main/user/user.module').then(m=>m.UserModule)
  }
  ,
  {
    path: 'support',
    loadChildren: () => import('./main/product/support-agreement/support-agreement.module').then(m => m.SupportAgreementModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./main/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'attendance',
    loadChildren: () => import('./main/attendance/attendance.module').then(m => m.AttendanceModule)
  },
  {
    path: 'projectmanagement',
    loadChildren: () => import ('./main/project/projectmangaement/projectmanagement.module').then(m => m.ProjectManagementModule),
  },
  {
    path: 'milestone',
    loadChildren: () => import ('./main/project/milestone/milestone.module').then(m => m.MilestoneModule),
  },
  {
    path: 'employeeticket',
    loadChildren: () => import ('./main/project/employeeticket/employeeticket.module').then(m => m.EmployeeticketModule),
  },
  {
    path: 'setup',
    loadChildren: () => import ('./main/Setup/setup-module.module').then(m=> m.SetupModuleModule),
  },
  // {
  //   path: 'timesheet',
  //   loadChildren: () => import('./main/timesheet/timesheet.module').then(m => m.TimesheetModule)
  // },
  {
    path: '**',
    redirectTo: '/dashboard/maindashboard'
  }
];


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,

    }), // ToastrModule added
    NgxSpinnerModule,
    RouterModule.forRoot(appRoutes, {useHash: true}),
    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // pristine modules
    pristineModule.forRoot(pristineConfig),
    pristineProgressBarModule,
    pristineSharedModule,
    pristineSidebarModule,
    pristineThemeOptionsModule,


    // App modules
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatPaginatorModule,
    MatDividerModule,
    MatTableModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
